import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import Layout from '../../components/layout/layout';

export default class TakeAction extends BaseComponent {
  protected declareTranslateCollection(): string {
    return 'takeAction';
  }

  public render(): ReactNode {
    return (
      <Layout
        title={this.translate('pageTitle')}
        description={this.translate('description')}
      >
        <div></div>
      </Layout>
    );
  }
}
